/* eslint-disable prettier/prettier */
/* eslint-disable no-irregular-whitespace */
import styles from './Outro.module.scss';
export const Outro = (): JSX.Element => {
	return (
		<div className={styles.outro_container}>
			<div className={styles.ascii_banner_container}>
				<pre className={styles.ascii_banner}>
					{`
  ___
  __|___|__
  ('o_o')
  _\\~-~/_
     //\\__/\\ \\  ∩
     / )-  -(\\ \\/_)
   \\ \\    / \\_/
)/_|  |_\\
// /(\\/)\\ \\
 /_/      \\_\\
 (_||      ||_)
 \\| |__| |/
 | |  | |
 | |  | |
 |_|  |_|
 /_\\  /_\\
					`}
				</pre>
			</div>
			<div className={styles.text_container}>
				Off you go then. Thanks for stopping by!
			</div>
		</div>
	);
};
